<template>
  <div id="Archive" ref="Archive" class="top-container d-flex bg-light">
    <div
      id="SearchSidebar"
      class=""
      :class="{ mobile: mobileFlag, desktop: mobileFlag == false }"
    >
      <div class="container text-left">
        <div
          id="SearchFilters"
          :class="{ mobile: mobileFlag, desktop: mobileFlag == false }"
        >
          <div class="row pt-4">
            <div class="col-12">
              <div class="d-flex">
                <h2>Transcripts Archive</h2>
                <h6 class="ml-2 text-muted">
                  [{{ numberOfMeetingsFiltered }}/{{ totalMeetings }}]
                </h6>
              </div>
              <!-- <p class="sub-title">
                Filter through transcripts using the controls below.
              </p> -->
            </div>
          </div>

          <hr />

          <h4>Filter by Date Range</h4>
          <DateSelector
            @date-change="handleDateChange"
            :minDate="null"
            :maxDate="null"
          />
          <!-- //TODO - add to archive -->

          <hr />

          <h4>Filter by Community Board</h4>
          <b-form-select
            v-model="communityBoardSelect"
            :options="communityBoardOptions"
          ></b-form-select>

          <div class="d-flex justify-content-center mb-4">
            <b-button
              variant="info"
              class="mt-4 flex-grow-1"
              @click="getTranscripts"
              >Submit</b-button
            >
            <b-button
              variant="outline-warning"
              class="mt-4 ml-2 flex-grow-2"
              @click="reset"
              >reset</b-button
            >
          </div>
        </div>

        <div
          id="TranscriptCards"
          class="row overflow-scroll"
          :class="{ mobile: mobileFlag, desktop: mobileFlag == false }"
        >
          <div class="col-12 p-2">
            <div class="text-danger p-4" v-if="transcripts.length === 0">
              <p>no transcripts found</p>
              <p>Please try a different filter</p>
            </div>

            <b-card
              no-body
              class="overflow-hidden text-left mt-4 mb-4"
              style="max-width: 740px"
              v-for="(transcript, i) in transcripts"
              :key="i"
              :class="{ active: transcript.active }"
              @click="setActiveTranscript(transcript)"
            >
              <b-row no-gutters>
                <b-col md="12">
                  <b-card-body :title="transcript.YoutubeMetadata.title">
                    <small class="text-muted">{{
                      transcript.CommunityBoardInfo.normalizedName
                    }}</small>
                    <div class="badges">
                      <span
                        class="badge badge-pill badge-info mr-1"
                        v-for="(badge, index) in transcript.properties
                          .meetingType"
                        :key="index"
                        >{{ badge[1] }}</span
                      >
                    </div>
                    <b-card-text>
                      <small>{{
                        transcript.YoutubeMetadata.publishDate
                      }}</small>
                      <!-- <p>
                        {{transcript.properties.readTimeExecSummary}}
                      </p> -->
                    </b-card-text>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </div>
      </div>
    </div>

    <div
      id="TranscriptContainer"
      ref="TranscriptContainer"
      v-if="showActiveTranscript"
      :class="{ mobile: mobileFlag, desktop: mobileFlag == false }"
      class="overflow-scroll"
    >
      <div id="TranscriptCloseButton" v-if="mobileFlag" class="text-muted p-2">
        <b-button
          variant="outline-secondary"
          @click="showActiveTranscript = false"
        >
          close
        </b-button>
      </div>
      <div id="ScrollToTop" @click="scrollToTop">
        <b-icon-chevron-double-up />
      </div>
      <div class="container">
        <div class="row" v-if="Object.keys(activeTranscript).length > 1">
          <div class="col-12 text-left p-4">
            <h3>{{ activeTranscript.YoutubeMetadata.title }}</h3>
            <h5 class="text-muted">
              {{ activeTranscript.CommunityBoardInfo.normalizedName }}
            </h5>
            <small>{{ activeTranscript.YoutubeMetadata.publishDate }}</small>
            <div class="badges">
              <span
                class="badge badge-pill badge-info mr-1"
                v-for="(badge, index) in activeTranscript.properties
                  .meetingType"
                :key="index"
                >{{ badge[1] }}</span
              >
            </div>
            <div class="d-flex flex-row align-items-center mt-2">
              <h3>
                <BIconCloudDownload
                  class="icon-button"
                  title="save transcript"
                  @click="saveTranscript(activeTranscript)"
                />
              </h3>
              <h3 class="ml-2">
                <a
                  :href="
                    'https://blockparty.studio/Archive/?_id=' +
                    activeTranscript._id
                  "
                  target="_blank"
                >
                  <BIconLink class="icon-button" title="link transcript" />
                </a>
              </h3>
              <socialMedia
                :tag="'youtube'"
                v-if="
                  activeTranscript.CommunityBoardInfo.youtubeChannelURL !== null
                "
                :url="
                  'https://www.youtube.com/watch?v=' +
                  activeTranscript.properties.videoURL
                "
              />
              <socialMedia
                :tag="'twitter'"
                v-if="
                  activeTranscript.CommunityBoardInfo.twitterHandle !== null
                "
                :url="`https://twitter.com/${activeTranscript.CommunityBoardInfo.twitterHandle}`"
              />
            </div>
            <pieChart
              class="mb-4"
              :data="activeTranscript.properties.wordCountSummary"
            />
            <div class="mb-4"></div>
            <h3 v-if="activeTranscript.properties.execSummary.length > 0">
              Meeting Highlights
            </h3>
            <div class="d-flex">
              <h4>
                <BIconClock class="icon-button" title="clock" />
              </h4>
              <p class="ml-2">
                {{ activeTranscript.properties.readTimeExecSummary }}
              </p>
            </div>
            <ul>
              <li
                v-for="(j, k) in activeTranscript.properties.execSummary"
                :key="'execsummary-' + k"
              >
                {{ j }}
              </li>
            </ul>
            <h4>Full Transcript</h4>
            <div id="fillerWords" class="d-flex mb-4">
              <div class="mr-2 bg-info p-1 text-light rounded">
                um's
                <span class="badge badge-light">{{
                  activeTranscript.properties.fillerWordCount.um
                }}</span>
              </div>
              <div class="bg-info p-1 text-light rounded">
                uh's
                <span class="badge badge-light">{{
                  activeTranscript.properties.fillerWordCount.uh
                }}</span>
              </div>
            </div>
            <div class="d-flex">
              <h4>
                <BIconClock class="icon-button" title="clock" />
              </h4>
              <p class="ml-2">
                {{ activeTranscript.properties.readTimeFullTranscript }}
              </p>
            </div>

            <p
              v-for="(paragraph, idx) in activeTranscript.properties
                .fullTranscript"
              :key="'fulltranscript-' + idx"
            >
              <a :href="paragraph" @click.prevent=""></a>
              {{ paragraph }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint no-unused-vars: "off"*/

import { mapGetters } from 'vuex'
import pieChart from '@/components/pie_chart.vue'
import socialMedia from '@/components/social_media.vue'
import DateSelector from '@/components/DateSelector.vue'
import axios from 'axios'
import { jsPDF } from 'jspdf'

import {
  BIconChevronDoubleUp,
  BIconCloudDownload,
  BIconClock,
  BIconLink
} from 'bootstrap-vue'

import {
  getCurrentWeek,
  getPrettyDate,
  getCurrentDate
} from '@/assets/js/helpers.js'

import { startingDate } from '@/assets/js/template.js'

export default {
  name: 'Archive',
  components: {
    DateSelector,
    pieChart,
    socialMedia,
    BIconChevronDoubleUp,
    BIconCloudDownload,
    BIconClock,
    BIconLink
  },
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null
      },
      communityBoardSelect: null,
      transcripts: [],
      activeTranscript: {},
      showActiveTranscript: true
    }
  },
  computed: {
    ...mapGetters({
      mobileFlag: 'getMobileFlag',
      communityBoardInfo: 'getCommunityBoardInfo',
      totalMeetings: 'getTotalMeetings'
    }),
    numberOfMeetingsFiltered() {
      return this.transcripts.length
    },
    communityBoardOptions() {
      return this.communityBoardInfo
        .reduce((accumulator, d) => {
          accumulator.push({ value: d.communityID, text: d.normalizedName })
          return accumulator
        }, [])
        .sort(function (a, b) {
          return a.text.localeCompare(b.text, undefined, {
            numeric: true,
            sensitivity: 'base'
          })
        })
    }
  },
  async mounted() {
    if (this.$route.query._id) {
      const params = {
        collectionName: 'transcripts_v4',
        find: { _id: this.$route.query._id },
        sort: {},
        project: {},
        _id: true
      }

      await this.readTranscripts(params)
    } else {
      await this.getTranscriptsByDate()
    }

    if (this.mobileFlag) {
      this.showActiveTranscript = false
    }

    if (this.$route.query.paragraph) {
      this.showActiveTranscript = true
      this.scrollToParagraph(this.$route.query.paragraph)
    }
  },
  methods: {
    scrollToParagraph(paragraph) {
      const element = document.querySelectorAll(`a[href*="${paragraph}"]`)[0]
      const topPos =
        element.getBoundingClientRect().top +
        window.scrollY -
        window.innerHeight / 2

      const scrollableElement = document.getElementById('TranscriptContainer')

      element.parentNode.classList.add('highlight-paragraph')

      scrollableElement.scrollTo({
        top: topPos,
        behavior: 'smooth' // smooth scroll
      })
    },
    scrollToTop() {
      const c = this.$refs['TranscriptContainer'].scrollTop

      if (c > 0) {
        window.requestAnimationFrame(this.scrollToTop)
        document.body.scrollTop = this.$refs['TranscriptContainer'].scrollTop =
          c - c / 8
      }
    },
    handleDateChange(newDateRange) {
      this.dateRange = newDateRange
    },
    async getTranscriptsByDate() {
      const [from, to] = getCurrentWeek()

      const params = {
        collectionName: 'transcripts_v4',
        find: {
          'YoutubeMetadata.publishDate': {
            $gt: new Date(from).toISOString(),
            $lt: new Date(to).toISOString()
          }
        },
        project: {},
        sort: { 'YoutubeMetadata.publishDate': -1 }, // move to server
        limit: 10 // move to server
      }

      await this.readTranscripts(params)
    },
    setActiveTranscript(transcript) {
      this.showActiveTranscript = false
      this.transcripts.forEach(d => {
        let v = false

        if (transcript._id === d._id) {
          // v = d.active === true ? false : true
          v = true
        }
        d.active = v
      })

      this.activeTranscript = {}

      if (transcript.active) {
        this.showActiveTranscript = true
        this.activeTranscript = transcript
      }
    },
    reset() {
      this.handleDateChange = {
        startDate: null,
        endDate: null
      }
      ;(this.communityBoardSelect = null), (this.transcripts = [])
    },
    getTranscripts() {
      let from,
        to = null
      let CBID = this.communityBoardSelect

      /**
       * check to see if date is given
       * if not date is given use April ( start of project ) and the current date
       */
      if (Object.values(this.dateRange).every(element => element === null)) {
        from = new Date(startingDate)
        to = getCurrentDate()
      } else {
        from = this.dateRange['startDate']
        to = this.dateRange['endDate']
      }

      const params = {
        collectionName: 'transcripts_v4',
        find: {
          'YoutubeMetadata.publishDate': {
            $gt: new Date(from).toISOString(),
            $lt: new Date(to).toISOString()
          }
        },
        project: {},
        sort: { 'YoutubeMetadata.publishDate': -1 },
        limit: 10
      }

      // if CBID == null, then only query with date range
      if (CBID !== null) {
        params.find['CommunityBoardInfo.communityID'] = CBID
      }

      // get transcripts
      this.readTranscripts(params)
    },
    async readTranscripts(params) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/readTranscripts`,
          params
        )
        const d = response.data.data

        this.formatTranscriptData(d)

        return
      } catch (error) {
        console.error('err', error)
        return
      }
    },
    saveTranscript(transcript) {
      const newLine = '\r\n\r\n'
      // add new lines for word documents
      const title = `${transcript.CommunityBoardInfo.normalizedName
        .toLowerCase()
        .replace(/\s/g, '_')}_${transcript.YoutubeMetadata.publishDate}.pdf`
      const text = transcript.properties.fullTranscript.map(d => {
        d += newLine
        return d
      })

      // add headers
      text.unshift(
        (transcript.YoutubeMetadata.title += newLine),
        (transcript.CommunityBoardInfo.normalizedName += newLine),
        (transcript.YoutubeMetadata.publishDate += newLine),
        'Transcript',
        newLine
      )

      // convert arr --> str
      let str = ''
      text.forEach(d => (str += d))
      // init
      const doc = new jsPDF()
      // word wrap
      const splitTitle = doc.splitTextToSize(str, 180)
      // create new pages based on a4 dim
      let y = 20
      splitTitle.forEach((d, i) => {
        if (y > 280) {
          y = 10
          doc.addPage()
        }
        doc.text(15, y, splitTitle[i])
        y = y + 7
      })

      doc.save(title)
    },
    formatTranscriptData(data) {
      this.transcripts = []
      this.activeTranscript = {}

      if (data.length > 0) {
        this.transcripts = data.map(d => {
          // console.debug('transcript object: ', d)

          d.YoutubeMetadata.publishDate = getPrettyDate(
            d.YoutubeMetadata.publishDate
          )
          d.properties.fullTranscript = d.properties.fullTranscript.split('\n')
          d.active = false

          try {
            d.properties.execSummary = d.properties.execSummary.split('\n')
            d.properties.meetingType = d.properties.meetingType.slice(0, 3)
          } catch (err) {
            d.properties.execSummary = []
            d.properties.meetingType = []
          }
          return d
        })

        this.activeTranscript = this.transcripts[0]
      }
    }
  }
}
</script>

<style lang="scss">
$filter-height: 350px;
$mobile-filter-height: 350px;
$sidebar-width: 400px;

#ScrollToTop {
  position: fixed;
  right: 20px;
  bottom: 15px;
  pointer-events: all;
  z-index: 5;
  background: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid $black;
  cursor: pointer;

  transition: all $short-transition;

  &:hover {
    background: $active-color;

    path {
      stroke: white;
    }
  }
}

.card {
  &.active {
    border: 4px solid $green;
  }
}
#Archive {
  overflow: hidden;
}
#SearchSidebar {
  &.mobile {
    position: fixed;
    width: 100%;
    max-height: 100vh !important;
  }
}
#SearchSidebar,
#TranscriptContainer {
  &.desktop {
    width: $sidebar-width;
    position: relative !important;
  }
}
#TranscriptContainer {
  background: white;

  &.desktop {
    height: $canvas-height;
    width: calc(100vw - #{$sidebar-width});
  }

  &.mobile {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 5;
  }

  #TranscriptCloseButton {
    display: flex;
    justify-content: flex-end;
    padding: 0 1rem;

    &:hover {
      color: $active-color;
    }
  }
}
#SearchFilters {
  height: $filter-height;

  &.mobile {
    height: $mobile-filter-height !important;
  }
}

#TranscriptCards {
  border-top: 1px solid $font-grey;
  height: calc(100vh - #{$navbar-height} - #{$filter-height} - 25px);

  .card-body {
    padding: 0.6rem !important;
  }

  &.mobile {
    height: calc(100vh - #{$navbar-height} - #{$mobile-filter-height} - 25px);
  }
}

.paragraph-archive {
  font-size: 16px;
  color: $off-black;
  text-decoration: none !important;
  cursor: text;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  user-select: all;

  &:hover {
    text-decoration: none !important;
  }

  &.highlight-paragraph > p {
    background: $paragraph-highlight;
    padding: 4px;
  }
}
</style>
