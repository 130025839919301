<template>
  <div id="DatePicker" class="d-flex">
    <date-range-picker
      class="date-range-picker"
      ref="picker"
      opens="center"
      :ranges="false"
      :singleDatePicker="'range'"
      v-model="dateRange"
      minDate="minDate"
      maxDate="maxDate"
      @update="onDateChange"
    >
    </date-range-picker>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  props: {
    minDate: String,
    maxDate: String
  },
  components: {
    DateRangePicker
  },
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null
      }
    }
  },
  methods: {
    onDateChange() {
      this.$emit('date-change', this.dateRange)
    }
  }
}
</script>

<style>
#DatePicker {
  width: 100%;
}

.date-range-picker {
  width: 100%;

  .form-control {
    border-radius: 20px;
  }
}
</style>
