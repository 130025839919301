<template>
  <div ref="chartContainer" id="chartContainer" class="p-4">
    <h6>Top Words Used in Meeting</h6>
    <svg :id="ID" :width="svgWidth" :height="svgHeight" style="overflow:visible!important;">
    </svg>
  </div>
</template>

<script>

import { select } from 'd3-selection'
import { scaleLinear, scaleBand } from 'd3-scale'
import { axisBottom, axisLeft } from 'd3-axis'

let svg = null,
  xScale = null,
  yScale = null

export default {
  name:"pieChart",
  components:{},
  data(){
    return {
      margin: {top: 10, right: 30, bottom: 50, left: 75},
      width: 150,
      height: 150
    }
  },
  props:{
    data: Object
  },
  watch: {
    data: {
      // This will let Vue know to look inside the array
      deep: true,
      // We have to move our method to a handler field
      handler() {
        this.init()
      }
    }
  },
  mounted(){
    this.init()
  },
  computed:{
    ID(){
      return 'chart'
    },
    svgWidth(){
      return this.width + this.margin.left + this.margin.right
    },
    svgHeight(){
      return this.height + this.margin.top + this.margin.bottom
    },
    chartData(){
      let object = this.data
      let d = []
      for (const key in object) {
        d.push({
          key: key,
          value: object[key]
        })
      }
      return d
    }
  },
  methods: {
    init(){
      // selectAll("*").remove();
      select('#'+this.ID).selectAll("*").remove()
      svg = select('#'+this.ID).append("g")
        .attr("transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")");

      let xMax = this.chartData.map((d)=> d.value).sort((a,b)=> a-b).reverse()

      xScale = scaleLinear().domain([0, xMax[0]]).range([ 0, this.svgWidth]);

      yScale = scaleBand().range([ 0, this.svgHeight ]).domain(this.chartData.map(function(d) { return d.key; })).padding(1);

      svg.append("g")
        .attr("transform", "translate(0," + this.svgHeight + ")")
        .call(axisBottom(xScale))
        .selectAll("text")
        .attr("transform", "translate(-10,0)rotate(-45)")
        .style("text-anchor", "end");

      svg.append("g")
        .call(axisLeft(yScale))

      this.drawPlot()
    },
    drawPlot(){
      // Lines
      svg.selectAll("myline")
        .data(this.chartData)
        .enter()
        .append("line")
          .attr("x1", function(d) { return xScale(d.value); })
          .attr("x2", xScale(0))
          .attr("y1", function(d) { return yScale(d.key); })
          .attr("y2", function(d) { return yScale(d.key); })
          .attr("stroke", "#555555")

      // Circles
      svg.selectAll("mycircle")
        .data(this.chartData)
        .enter()
        .append("circle")
          .attr("cx", function(d) { return xScale(d.value); })
          .attr("cy", function(d) { return yScale(d.key); })
          .attr("r", "6")
          .style("fill", "#69b3a2")
          // .attr("stroke", "black")
    },
  }
}
</script>

<style lang="scss" scoped>

</style>