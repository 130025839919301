export function getCurrentDate () {
  return new Date(new Date().setDate(new Date().getDate()))
}

export function getCurrentWeek () {
  let curr = new Date(new Date().setDate(new Date().getDate() - 14))

  let first = curr.getDate() - curr.getDay();
  // let last = first + 6;

  let from = new Date(curr.setDate(first)).toUTCString();
  let to = getCurrentDate()

  return [from, to]
}

export function getPrettyDate(isoDate) {

  let date = new Date(isoDate)

  const year = date.getFullYear()

  const dateString = date.toString().split(year)[0].trim()

  return dateString + ", " + year
}
